.app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.background {
  width: 100%;
  height: 60vh;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(-180deg, #fff 50%, #f4f6f8 100%);
}

.header {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
}

.title {
  position: absolute;
  font-size: 4rem;
  font-weight: 700;
  color: #333;
}

.titleInner {
  color: #b51432;
}

.byZenika {
  position: absolute;
  right: 0;
  top: 5rem;
  width: 150px;
}

.main {
  position: relative;
  height: 100%;
  width: 100%;
}

.canvas {
  height: 100%;
  flex-grow: 1;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: absolute;
  display: flex;
  flex-direction: column;
}

.canvas > * {
  display: block;
  width: auto;
  margin: 0 auto;
  height: 100%;
  max-width: 100%;
  margin: 50px;
}

.loading {
  color: #828282;
  display: flex;
  justify-content: center;
  align-items: center;
}

.categories {
  position: absolute;
}

.categoriesInner {
  display: flex;
  padding: 20px;
}

.categoriesInner > .categoryButton {
  border-radius: 3px;
  height: 60px;
  font-size: 1.4rem;
  font-weight: 400;
  cursor: pointer;
  border: 1px solid gainsboro;
  background: gainsboro;
  transition: background-color 100ms ease;
}

.categoriesInner > button.selected {
  border: 3px solid #b51432;
}

.actions {
  position: absolute;
  text-align: center;
  display: flex;
}

.actions > button.circle {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid gainsboro;
  background: gainsboro;
  cursor: pointer;
  transition: background-color 100ms ease;
}

.categoriesInner > .categoryButton:hover:not(.selected),
.actions > button.circle:hover {
  background-color: rgb(210, 210, 210);
  transition: none;
}

.categoriesInner > .categoryButton:focus,
.actions > button.circle:focus {
  outline: none;
}

.categoriesInner > .categoryButton:active:not(.selected),
.actions > button.circle:active {
  background-color: rgb(190, 190, 190);
}

@media (min-width: 800px) {
  .categories {
    max-height: 100%;
  }

  .categoriesInner {
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    white-space: nowrap;
    max-height: calc(100vh - 30px);
  }

  .categoriesInner > button {
    margin-bottom: 10px;
  }

  .categoriesLeft {
    width: 200px;
    top: 50%;
    left: 3%;
    transform: translate3d(0, -50%, 0);
  }

  .categoriesRight {
    top: 50%;
    right: 3%;
    transform: translate3d(0, -50%, 0);
  }

  .actions {
    width: 50%;
    bottom: 20px;
    left: 25%;
    justify-content: center;
  }

  .actions > button {
    margin: 0 10px;
  }

  .netlify {
    position: absolute;
    display: block;
    bottom: 10px;
    left: 10px;
  }
}

@media (max-width: 800px) {
  .title {
    font-size: 2rem;
  }

  .byZenika {
    top: 3rem;
    width: 100px;
  }

  button {
    font-size: 1.5rem;
  }

  .categories {
    max-width: 100%;
  }

  .categoriesInner {
    overflow-y: hidden;
    overflow-x: scroll;
    white-space: nowrap;
    width: 100%;
  }

  .categoriesInner > button {
    margin-right: 10px;
  }

  .categoriesLeft {
    top: 5rem;
    width: 100%;
    overflow: auto;
  }

  .categoriesRight {
    bottom: 0;
    width: 100%;
    overflow: auto;
  }

  .actions {
    top: 50%;
    right: 3%;
    transform: translate3d(0, -50%, 0);
    flex-direction: column;
  }

  .actions > button {
    margin: 10px 0;
  }

  .netlify {
    display: none;
  }
}
