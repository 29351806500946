.button {
  border: 3px solid gainsboro;
  background: gainsboro;
  border-radius: 5px;
  font-size: 1.4rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  cursor: pointer;
  transition: background-color 100ms ease;
}

.button:hover:not(.selected) {
  background-color: rgb(210, 210, 210);
  transition: none;
}

.button:focus {
  outline: none;
}

.button:active:not(.selected) {
  background-color: rgb(190, 190, 190);
}

.button > svg {
  width: 60px;
  height: 60px;
}

.icon {
  display: block;
}

.selected {
  border: 3px solid #b51432;
}

@media (min-width: 800px) {
  .button {
    margin-bottom: 10px;
  }
}

@media (max-width: 800px) {
  .button {
    font-size: 1.5rem;
    margin-right: 10px;
  }
}
