@import url(https://fonts.googleapis.com/css2?family=Paytone+One&display=swap);
body {
  margin: 0;
  font-family: 'Paytone One';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: 'Paytone One';
}

*,
:before,
:after {
  margin: 0;
  box-sizing: border-box;
}

.AssetButton_button__oKNQf {
  border: 3px solid gainsboro;
  background: gainsboro;
  border-radius: 5px;
  font-size: 1.4rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  cursor: pointer;
  transition: background-color 100ms ease;
}

.AssetButton_button__oKNQf:hover:not(.AssetButton_selected__3N30I) {
  background-color: rgb(210, 210, 210);
  transition: none;
}

.AssetButton_button__oKNQf:focus {
  outline: none;
}

.AssetButton_button__oKNQf:active:not(.AssetButton_selected__3N30I) {
  background-color: rgb(190, 190, 190);
}

.AssetButton_button__oKNQf > svg {
  width: 60px;
  height: 60px;
}

.AssetButton_icon__3b1Er {
  display: block;
}

.AssetButton_selected__3N30I {
  border: 3px solid #b51432;
}

@media (min-width: 800px) {
  .AssetButton_button__oKNQf {
    margin-bottom: 10px;
  }
}

@media (max-width: 800px) {
  .AssetButton_button__oKNQf {
    font-size: 1.5rem;
    margin-right: 10px;
  }
}

.Notification_notification__pERJa {
  background: #fff;
  max-width: 500px;
  min-width: 300px;
  padding: 1rem;
  color: #333333;
  border-radius: 5px;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.24);
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  transition: 0.3s;
  display: flex;
  align-items: center;
}

@media (max-width: 800px) {
  .Notification_notification__pERJa {
    width: 100%;
    right: 0;
    bottom: 110px;
    border-radius: 0px;
    flex-wrap: nowrap;
    flex-direction: row;
  }

  .Notification_notification__pERJa > p {
    flex-grow: 1;
  }
}

.Notification_button__2Xn8q {
  background: transparent;
  padding: 0.4rem 1rem;
  border: none;
  border-radius: 2px;
  outline: none;
  text-transform: uppercase;
  font-size: 0.8rem;
  white-space: nowrap;
  cursor: pointer;
  transition: 0.2s;
}

.Notification_primary__1Qe8S {
  background: #b51432;
  color: #fff;
  box-shadow: 0 0 2px rgba(#b51432, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);
  margin-left: 1rem;
}

.Notification_primary__1Qe8S:hover {
  background: #b51432;
  box-shadow: 0 0 4px rgba(#b51432, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
}

.App_app__1kX79 {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.App_background__3CDGK {
  width: 100%;
  height: 60vh;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(-180deg, #fff 50%, #f4f6f8 100%);
}

.App_header__3ZZ1n {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
}

.App_title__-GfWs {
  position: absolute;
  font-size: 4rem;
  font-weight: 700;
  color: #333;
}

.App_titleInner__22sKs {
  color: #b51432;
}

.App_byZenika__3jtTW {
  position: absolute;
  right: 0;
  top: 5rem;
  width: 150px;
}

.App_main__3ZkGI {
  position: relative;
  height: 100%;
  width: 100%;
}

.App_canvas__1KgDx {
  height: 100%;
  flex-grow: 1;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: absolute;
  display: flex;
  flex-direction: column;
}

.App_canvas__1KgDx > * {
  display: block;
  width: auto;
  margin: 0 auto;
  height: 100%;
  max-width: 100%;
  margin: 50px;
}

.App_loading__wCN4P {
  color: #828282;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App_categories__5keei {
  position: absolute;
}

.App_categoriesInner__rcAb9 {
  display: flex;
  padding: 20px;
}

.App_categoriesInner__rcAb9 > .App_categoryButton__3VQ_Y {
  border-radius: 3px;
  height: 60px;
  font-size: 1.4rem;
  font-weight: 400;
  cursor: pointer;
  border: 1px solid gainsboro;
  background: gainsboro;
  transition: background-color 100ms ease;
}

.App_categoriesInner__rcAb9 > button.App_selected__3QRXT {
  border: 3px solid #b51432;
}

.App_actions__2n_Tz {
  position: absolute;
  text-align: center;
  display: flex;
}

.App_actions__2n_Tz > button.App_circle__1CWmI {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid gainsboro;
  background: gainsboro;
  cursor: pointer;
  transition: background-color 100ms ease;
}

.App_categoriesInner__rcAb9 > .App_categoryButton__3VQ_Y:hover:not(.App_selected__3QRXT),
.App_actions__2n_Tz > button.App_circle__1CWmI:hover {
  background-color: rgb(210, 210, 210);
  transition: none;
}

.App_categoriesInner__rcAb9 > .App_categoryButton__3VQ_Y:focus,
.App_actions__2n_Tz > button.App_circle__1CWmI:focus {
  outline: none;
}

.App_categoriesInner__rcAb9 > .App_categoryButton__3VQ_Y:active:not(.App_selected__3QRXT),
.App_actions__2n_Tz > button.App_circle__1CWmI:active {
  background-color: rgb(190, 190, 190);
}

@media (min-width: 800px) {
  .App_categories__5keei {
    max-height: 100%;
  }

  .App_categoriesInner__rcAb9 {
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    white-space: nowrap;
    max-height: calc(100vh - 30px);
  }

  .App_categoriesInner__rcAb9 > button {
    margin-bottom: 10px;
  }

  .App_categoriesLeft__LrOhJ {
    width: 200px;
    top: 50%;
    left: 3%;
    transform: translate3d(0, -50%, 0);
  }

  .App_categoriesRight__3w7WZ {
    top: 50%;
    right: 3%;
    transform: translate3d(0, -50%, 0);
  }

  .App_actions__2n_Tz {
    width: 50%;
    bottom: 20px;
    left: 25%;
    justify-content: center;
  }

  .App_actions__2n_Tz > button {
    margin: 0 10px;
  }

  .App_netlify__1GhWt {
    position: absolute;
    display: block;
    bottom: 10px;
    left: 10px;
  }
}

@media (max-width: 800px) {
  .App_title__-GfWs {
    font-size: 2rem;
  }

  .App_byZenika__3jtTW {
    top: 3rem;
    width: 100px;
  }

  button {
    font-size: 1.5rem;
  }

  .App_categories__5keei {
    max-width: 100%;
  }

  .App_categoriesInner__rcAb9 {
    overflow-y: hidden;
    overflow-x: scroll;
    white-space: nowrap;
    width: 100%;
  }

  .App_categoriesInner__rcAb9 > button {
    margin-right: 10px;
  }

  .App_categoriesLeft__LrOhJ {
    top: 5rem;
    width: 100%;
    overflow: auto;
  }

  .App_categoriesRight__3w7WZ {
    bottom: 0;
    width: 100%;
    overflow: auto;
  }

  .App_actions__2n_Tz {
    top: 50%;
    right: 3%;
    transform: translate3d(0, -50%, 0);
    flex-direction: column;
  }

  .App_actions__2n_Tz > button {
    margin: 10px 0;
  }

  .App_netlify__1GhWt {
    display: none;
  }
}

